<template>
  <div class="progress-spacing">
    <div class="d-flex align-items-center lets_test_this">
      <div class="icon_wrap">
        <p class="new-back" v-if="currentValue === 0">
          <i class="fa margin-check fa-check"></i>
        </p>
        <div class="arrows-down" v-if="currentValue === 0"></div>
        <img src="~@/assets/search-purple.png" alt="" srcset="" />
        <p class="icon_text active-text">Explorer</p>
      </div>
      <div class="barWrapper">
        <div class="progress">
          <div
            class="progress-bar-one"
            :style="{ width: `${currentValue}%` }"
            role="progressbar"
          >
            <span
              id="prog"
              class="popOver"
              data-toggle="tooltip"
              data-placement="top"
              data-trigger="manual"
              :title="`${currentValue}%`"
            >
            </span>
            <div
              class="tooltip_box"
              ref="tooltip"
              :class="{tooltip_box_talent : currentValue ===200}"
              :style="{
                left: `${
                  currentValue < 100
                    ? currentValue - 12
                    : currentValue > 100 && currentValue < 200
                    ? currentValue + 10
                    : currentValue === 100
                    ? currentValue
                    : currentValue + 20
                }%`,
                top: `${
                  currentValue < 50 ? null : currentValue > 50 ? -50 : -54
                }px`,
              }"
              v-if="currentValue > 0"
            >
              <span class="popOver custom_tooltip" v-if="currentValue < 100"
                >{{ currentValue / 2 }}%</span
              >
              <span
                class="popOver custom_tooltip"
                :class="{
                  talent__tooltip: currentValue > 100,
                  'rising-star__tooltip': currentValue <= 100,
                }"
                v-else
                ><span v-if="currentValue > 100 && currentValue < 200"
                  >{{ currentValue / 2 }}%</span
                >
                <i v-else class="fa margin-check fa-check"></i
              ></span>
              <div
                class="arrows-down-progress"
                :class="{
                  'rising-star__arrow': currentValue === 100,
                  talent__arrow: currentValue > 100,
                }"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="icon_wrap" v-if="currentValue < 100">
        <img src="~@/assets/arrow-grey.png" alt="" srcset="" />
        <p class="icon_text">Rising Star</p>
      </div>
      <div class="icon_wrap" v-else>
        <img src="~@/assets/arrow-purple.png" alt="" srcset="" />
        <p class="icon_text active_second">Rising Star</p>
      </div>
      <div class="barWrapper">
        <div class="progress">
          <div
            class="progress-bar-two"
            :style="{ width: `${currentValue - 100}%` }"
            role="progressbar"
          >
            <span
              class="popOver"
              data-toggle="tooltip"
              data-placement="top"
              title="currentValue"
            >
            </span>
          </div>
        </div>
      </div>
      <div class="icon_wrap" v-if="currentValue < 200">
        <img src="~@/assets/star-grey.png" alt="" srcset="" />
        <p class="icon_text">Talent</p>
      </div>
      <div class="icon_wrap" v-else>
        <img src="~@/assets/star-purple.png" alt="" srcset="" />
        <p class="icon_text active_second">Talent</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RisingStarProgressBar',
  props: ['currentValue'],
  // data() {
  //   return {
  //     currentValue: 200,
  //   };
  // },
};
</script>

<style scoped>
.tooltip {
  position: relative;
  float: right;
}

.progress-spacing {
  margin: 70px auto 50px;
  max-width: 487px;
}

.tooltip > .tooltip-inner {
  background-color: #eebf3f;
  padding: 5px 15px;
  color: #5a2871;
  font-weight: bold;
  font-size: 13px;
}
.active-text {
  color: #5a2871 !important;
}
.popOver + .tooltip > .tooltip-arrow {
  /* border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid #eebf3f; */
}

section {
  margin: 100px auto;
  height: 1000px;
}
.progress {
  overflow: visible;
  height: 6px;
  position: relative;
}
.active_second {
  color: #8a2372 !important;
}
.progress-bar-one {
  background: linear-gradient(to right, #5a2871, #7c2f70);
  -webkit-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out;
}
.progress-bar-two {
  background: linear-gradient(to right, #7c2f70, #bc1e73);
  -webkit-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out;
}
.progress {
  background-color: #dbdbdb !important;
}
.icon_wrap {
  position: relative;
  color: #dbdbdb;
  font-weight: 600;
}
.icon_text {
  margin: 0;
  position: absolute;
  margin-top: 16px;
  color: #dbdbdb;
  white-space: nowrap;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
}
.barWrapper {
  width: 100%;
}
.new-back {
  position: absolute;
  top: -40px;
  left: -4px;
  background: #5a2871;
  padding: 2px 13px;
  border-radius: 4px;
}
.arrows-down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #5a2871;
  position: absolute;
  left: 39%;
  top: -15px;
}
.new-back i {
  font-size: 18px;
  color: #fff;
}
.tooltip_box {
  position: absolute;
  bottom: 25px;
}
.custom_tooltip {
  color: #fff;
  /* position: absolute;
    top: 14%; */
  background-color: #5a2871;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
}
.rising-star__tooltip {
  background-color: #7c2f70 !important;
}
.talent__tooltip {
  background-color: #bc1e73 !important;
}
.rising-star__arrow {
  border-top: 10px solid #7c2f70 !important;
}
.talent__arrow {
  border-top: 10px solid #bc1e73 !important;
}

.arrows-down-progress {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #5a2871;
  position: absolute;
  left: 39%;
}
.margin-check {
  margin-top: 3px;
}
</style>
<style>
@media (max-width: 767px) {
  .tooltip_box_talent {
    left: 230% !important;
  }
}
</style>
